
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    // sweeps earned from sharing ecards
    // 0 if the user was limited from earning
    referralSweepsEarned: 0,
    // sweeps earned for first-time sharers who
    // opted in.
    bonusSweepsEarned: 0,
    facebookShareUrl: '',
    xShareUrl: '',
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateReferralCount (state, count) {
        state.referralCount = count;
    },
    updateSweepsEarned (state, {
        referralSweepsEarned = 0,
        bonusSweepsEarned = 0,
    } = {}) {
        state.referralSweepsEarned = referralSweepsEarned;
        state.bonusSweepsEarned = bonusSweepsEarned;
    },
    updateSocialShareUrls (state, { xShareUrl, facebookShareUrl }) {
        state.xShareUrl = xShareUrl;
        state.facebookShareUrl = facebookShareUrl;
    },
};

const actions = {
    async sendECard ({ dispatch }, { to_name, to_email, card_id, message }) {
        return dispatch('makeCall', {
            endpoint: 'viral/:ecard',
            data: { to_name, to_email, card_id, message },
        });
    },

    async sendViral ({ dispatch }, { formData }) {
        const data = { ...formData };

        return dispatch('makeCall', {
            endpoint: 'viral',
            data,
        });
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }

        const { result } = response.data || {};

        if (result?.referralCount !== undefined) {
            commit('updateReferralCount', result.referralCount);
        }
        if (
            result?.referralSweepsEarned !== undefined ||
            result?.bonusSweepsEarned !== undefined
        ) {
            commit('updateSweepsEarned', result);
        }

        if (
            result?.facebookShareUrl !== undefined ||
            result?.xShareUrl !== undefined
        ) {
            commit('updateSocialShareUrls', result);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
